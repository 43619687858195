<template>
  <v-list-item v-slot="{ active }" :value="teamMember.email" disabled>
    <v-list-item-action>
      <v-checkbox :input-value="active" disabled />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        {{ teamMember.fullName }}
        <span class="ml-2 text--secondary font-italic">
          {{ email }}
        </span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "RecipientListItem",
  inheritAttrs: false,
  props: {
    teamMember: {
      type: Object,
      default: () => ({
        fullName: "",
        email: "",
      }),
    },
  },
  computed: {
    email() {
      return this.teamMember.email
        ? this.teamMember.email
        : this.$t(
            "settings.automation.nightlyReports.recipient.listItem.noEmailText"
          );
    },
  },
};
</script>
