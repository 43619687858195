import Vue from "vue";

import { format, parseISO } from "date-fns";
import { AutomationCard } from "../components";
import { AutomationStatus } from "../constants";

export function useAutomation({ type }) {
  return Vue.extend({
    components: {
      AutomationCard,
    },
    props: {
      automationStatus: {
        type: Object,
        required: true,
      },
    },
    computed: {
      automation() {
        const index = this.$t("settings.automation.items").findIndex(
          (item) => item.type === type
        );
        const automation = this.$t(`settings.automation.items[${index}]`);
        const {
          status = AutomationStatus.NOT_CONNECTED,
          metadata = {},
          lastSyncDate,
        } = this.automationStatus;

        return {
          ...automation,
          status,
          lastStatus: this.$t(
            `settings.automation.items[${index}].lastStatus`,
            {
              ...metadata,
              lastSyncDate: lastSyncDate
                ? format(parseISO(lastSyncDate), "M/d/yy")
                : null,
            }
          ),
        };
      },
    },
  });
}
