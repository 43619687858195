<template>
  <v-card class="automation-card" v-bind="$attrs">
    <AutomationCardLogo
      :file-name="automation.logoFileName"
      :alt="automation.logoAlt"
      :loading="loading"
    />
    <v-card-title class="text-h6">
      <v-skeleton-loader type="heading" :loading="loading" width="100%">
        {{ automation.title }}
      </v-skeleton-loader>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader type="paragraph" :loading="loading">
        {{ automation.description }}
      </v-skeleton-loader>
    </v-card-text>
    <AutomationCardLastStatus>{{ lastStatus }}</AutomationCardLastStatus>
    <v-card-actions>
      <AutomationCardStatus :status="status" />
      <v-spacer></v-spacer>
      <v-skeleton-loader type="button" :loading="loading">
        <VxBtn text @click="connect">{{ actionBtnText }}</VxBtn>
      </v-skeleton-loader>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VxBtn } from "@/core-ui";
import { AutomationStatus } from "../../constants";
import AutomationCardLogo from "./AutomationCardLogo.vue";
import AutomationCardStatus from "./AutomationCardStatus.vue";
import AutomationCardLastStatus from "./AutomationCardLastStatus.vue";

export default {
  name: "AutomationCard",
  components: {
    VxBtn,
    AutomationCardLogo,
    AutomationCardStatus,
    AutomationCardLastStatus,
  },
  inheritAttrs: false,
  props: {
    automation: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        lastStatus: "",
        logoFileName: "",
        logoAlt: "",
        status: "",
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actionBtnText() {
      if (this.status === AutomationStatus.ACTIVE) {
        return this.$t("settings.automation.card.activeBtnText");
      } else if (this.status === AutomationStatus.CONNECTED) {
        return this.$t("settings.automation.card.connectedBtnText");
      } else if (this.status === AutomationStatus.ERROR) {
        return this.$t("settings.automation.card.errorBtnText");
      }
      return this.$t("settings.automation.card.notConnectedBtnText");
    },
    lastStatus() {
      return this.status === AutomationStatus.ACTIVE
        ? this.automation.lastStatus
        : "";
    },
    status() {
      return this.automation.status ?? AutomationStatus.NOT_CONNECTED;
    },
  },
  methods: {
    connect() {
      this.$emit("connect", this.automation);
    },
  },
};
</script>

<style lang="scss">
.automation-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto) 1fr auto 50px;
  border-radius: 5px !important;
}
</style>
