import AutomationView from "./Index.vue";
import WhenIWorkView from "./WhenIWorkView";
import HomebaseView from "./HomebaseView";
import NightlyReportsView from "./NightlyReportsView";
import ConstantContactView from "./ConstantContactView";
import FivestarsView from "./FivestarsView";

export {
  AutomationView,
  WhenIWorkView,
  HomebaseView,
  NightlyReportsView,
  ConstantContactView,
  FivestarsView,
};
export default AutomationView;
