<template>
  <v-list-item :key="report.subject" class="pl-0">
    <v-list-item-content>
      <v-list-item-title>{{ report.subject }}</v-list-item-title>
      <v-list-item-subtitle>{{ emails }}</v-list-item-subtitle>
      <span class="text-overline">{{ sentAt }}</span>
    </v-list-item-content>
    <v-list-item-icon class="align-self-center">
      <v-icon color="success">$success</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  name: "ReviewReportListItem",
  props: {
    report: {
      type: Object,
      default: () => ({
        subject: "",
        emails: [],
        sentAt: null,
      }),
    },
  },
  computed: {
    emails() {
      return this.report.emails.join(", ");
    },
    sentAt() {
      return format(
        typeof this.report.sentAt === "string"
          ? parseISO(this.report.sentAt)
          : this.report.sentAt,
        "MMMM dd, yyyy HH:mm a"
      );
    },
  },
};
</script>
