<template>
  <div class="recipient-list">
    <v-list
      subheader
      outlined
      class="mb-2 overflow-y-auto"
      :max-height="maxHeight"
    >
      <v-list-item-group v-model="selectedTeamMemberEmails" multiple>
        <template v-for="role in teamMemberRoles">
          <v-subheader :key="role.key" :title="role.name" class="text-overline">
            {{ role.name }}
          </v-subheader>
          <RecipientListItem
            v-for="(member, index) in teamMemberGroupedByRole[role.key]"
            :key="`${member.email}_${index}`"
            :team-member="member"
          />
        </template>
      </v-list-item-group>
    </v-list>
    <VxFormActions>
      <span class="text-body-1 px-2">
        {{ totalSelectedTeamMembers }}
      </span>
    </VxFormActions>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import { titleCase } from "@/utils/titleCase";
import { VxFormActions } from "@/core-ui";
import { TeamMemberRole } from "../../constants";
import RecipientListItem from "./RecipientListItem.vue";

function pruneAndMapEmail(array) {
  return array.filter((x) => !!x.email).map((x) => x.email);
}

export default {
  name: "RecipientList",
  components: { RecipientListItem, VxFormActions },
  props: {
    teamMembers: {
      type: Array,
      default: () => [],
    },
    sendToOwners: {
      type: Boolean,
      default: null,
    },
    sendToDistrictManagers: {
      type: Boolean,
      default: null,
    },
    sendToManagers: {
      type: Boolean,
      default: null,
    },
    maxHeight: {
      type: [String, Number],
      default: 500,
    },
  },
  computed: {
    selectedTeamMemberEmails() {
      return [
        ...(this.sendToOwners ? this.ownerEmails : []),
        ...(this.sendToDistrictManagers ? this.districtManagerEmails : []),
        ...(this.sendToManagers ? this.managerEmails : []),
      ];
    },
    ownerEmails() {
      return pruneAndMapEmail(
        this.teamMemberGroupedByRole[TeamMemberRole.OWNER]
      );
    },
    districtManagerEmails() {
      return pruneAndMapEmail(
        this.teamMemberGroupedByRole[TeamMemberRole.DISTRICT_MANAGER]
      );
    },
    managerEmails() {
      return pruneAndMapEmail(
        this.teamMemberGroupedByRole[TeamMemberRole.MANAGER]
      );
    },
    teamMemberGroupedByRole() {
      return groupBy(this.teamMembers, "roleName");
    },
    teamMemberRoles() {
      return Object.keys(this.teamMemberGroupedByRole)
        .filter((key) => key !== "support" && key !== "user")
        .map((key) => ({
          key,
          name: this.$t(`settings.automation.nightlyReports.roles.${key}`),
        }));
    },
    totalSelectedTeamMembers() {
      return `${this.selectedTeamMemberEmails.length} / ${this.teamMembers.length}`;
    },
  },
};
</script>
