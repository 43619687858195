<script>
export default {
  name: "AutomationCardLastStatus",
  functional: true,
  render(h, context) {
    return h(
      "div",
      {
        class:
          "automation-card__last-status px-4 mb-2 text--secondary text-caption font-italic text-ellipsis",
      },
      [context.slots()?.default]
    );
  },
};
</script>
