<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <ConnectAccountSetup
      :automation="automation"
      :accounts="accounts"
      :type="completedSetups[0]"
      :store-number="currentStoreNumber"
      @add:account="onAccountAdd"
      @select:account="onAccountSelect"
      @update:automation="onAutomationUpdate"
      @sync:locations="onLocationsSync"
      @next="onNext"
    />
    <OptionalSettingsSetup
      :type="completedSetups[1]"
      :settings="automation.settings"
      :disabled="!enableOptionalSettings"
      @update:settings="onSettingsUpdate"
      @next="onNext"
    />
    <TrainTeamSetup />
    <ConnectAccountDialog ref="connectAccountDialog" @addKey="onApiKeyAdd" />
  </RaiSetups>
</template>

<script>
import { mapState } from "vuex";
import { subscribeOneOff } from "@/utils/graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { RaiSetups, SetupType } from "@/core-ui";
import { useAccountView } from "./mixins/useAccountView";
import {
  ConnectAccountDialog,
  ConnectAccountSetup,
  TrainTeamSetup,
  OptionalSettingsSetup,
} from "./components/Homebase";

import {
  HOMEBASE_ACCOUNT_ADD,
  HOMEBASE_ACCOUNTS_SELECT_QUERY,
  HOMEBASE_AUTOMATION_QUERY,
  HOMEBASE_AUTOMATION_UPDATE,
  HOMEBASE_LOCATIONS_SYNC,
  HOMEBASE_LOCATIONS_SYNCED_SUBSCRIPTION,
  HOMEBASE_ACCOUNT_ADD_SUBSCRIPTION,
  HOMEBASE_ACCOUNT_SYNCED_SUBSCRIPTION,
} from "./graphql/Homebase";

export default {
  name: "HomebaseView",
  components: {
    RaiSetups,
    ConnectAccountSetup,
    ConnectAccountDialog,
    TrainTeamSetup,
    OptionalSettingsSetup,
  },
  mixins: [
    hasSnackbarAccess,
    useAccountView({
      prop: "homebaseAccounts",
      query: HOMEBASE_ACCOUNTS_SELECT_QUERY,
    }),
  ],
  data: (vm) => ({
    activeSetup: 0,
    loading: false,
    automation: vm.buildDefaultAutomation(),
    completedSetups: new Array(2),
  }),
  apollo: {
    automation: {
      query: HOMEBASE_AUTOMATION_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return response.homebaseAutomation || this.buildDefaultAutomation();
      },
      skip() {
        return !this.storeId;
      },
      watchLoading(isLoading) {
        this.setLoading(isLoading);
      },
    },
    $subscribe: {
      homebaseAccountAdded: {
        query: HOMEBASE_ACCOUNT_ADD_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      homebaseAccountSynced: {
        query: HOMEBASE_ACCOUNT_SYNCED_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      homebaseLocationsSynced: {
        query: HOMEBASE_LOCATIONS_SYNCED_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.storeId,
            integrationHomebaseId: this.automation?.integrationHomebaseId,
          };
        },
        skip() {
          return !this.storeId || !this.hasConnectedAccount;
        },
      },
    },
  },
  computed: {
    ...mapState("auth", { userId: "user_id" }),
    enableOptionalSettings() {
      const { homebaseLocationId } = this.automation || {};
      return this.hasConnectedAccount && homebaseLocationId > 0;
    },
    hasConnectedAccount() {
      return this.automation?.integrationHomebaseId > 0;
    },
  },
  methods: {
    buildDefaultAutomation() {
      return {
        integrationHomebaseId: null,
        homebaseLocationId: null,
        settings: {},
      };
    },
    setLoading(value) {
      this.loading = value;
    },
    onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
      this.activeSetup++;
    },
    onAccountAdd() {
      this.$refs.connectAccountDialog.show();
    },
    async onApiKeyAdd(context) {
      this.subscribeToAccountAdded(context);
      this.subscribeToAccountSynced(context);

      await this.$apollo.mutate({
        mutation: HOMEBASE_ACCOUNT_ADD,
        variables: {
          storeId: this.storeId,
          input: context.values,
        },
      });
    },
    onAccountSelect(values) {
      if (!this.automation) {
        this.automation = {};
      }

      this.automation.integrationHomebaseId = values.integrationHomebaseId;
    },
    subscribeToAccountAdded(context) {
      subscribeOneOff(this, {
        name: "homebaseAccountAdded",
        resolve: (result) => {
          if (!result.success) {
            context.setErrors(result.errors);
            return;
          }

          const successText = this.$t(
            "settings.automation.homebase.connectAccount.addedSuccessText"
          );

          context.setStatus(successText);
        },
      });
    },
    subscribeToAccountSynced(context) {
      subscribeOneOff(this, {
        name: "homebaseAccountSynced",
        resolve: async (result) => {
          if (!result.success) {
            context.setErrors(result.errors);
            return;
          }

          const successText = this.$t(
            "settings.automation.homebase.connectAccount.syncedSuccessText"
          );

          context.setStatus(successText);
          this.showSnackbar({ text: successText });
          await this.$apollo.queries.accountList.refetch();
          context.resolve();
        },
      });
    },
    async onAutomationUpdate({ values, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: HOMEBASE_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      if (data.errors?.length > 0) {
        console.error("ERROR: Homebase Automation Update");
        return;
      }

      this.automation = {
        ...this.automation,
        ...values,
        ...data.homebaseAutomationUpdate.automation,
      };
      resolve();
    },
    async onSettingsUpdate({ values, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: HOMEBASE_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: { settings: values },
        },
      });

      if (data.errors?.length > 0) {
        console.error("ERROR: Homebase Settings Update");
        return;
      }

      this.automation = {
        ...this.automation,
        ...values,
        ...data.homebaseAutomationUpdate.automation,
      };
      resolve();
    },
    async onLocationsSync(context) {
      this.subscribeToLocationsSynced(context);

      await this.$apollo.mutate({
        mutation: HOMEBASE_LOCATIONS_SYNC,
        variables: {
          storeId: this.storeId,
          integrationHomebaseId: context.values.integrationHomebaseId,
        },
      });
    },
    subscribeToLocationsSynced(context) {
      subscribeOneOff(this, {
        name: "homebaseLocationsSynced",
        resolve: async (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.homebase.connectAccount.schedule.syncSuccessText"
                )
              : this.$t(
                  "settings.automation.homebase.connectAccount.schedule.syncErrorText"
                ),
          });

          await this.$apollo.queries.accountList.refetch();

          context.resolve();
        },
      });
    },
  },
};
</script>
