<template>
  <AccountSetup
    :accounts="accounts"
    v-bind="$attrs"
    @submit="updateAutomation"
    @change:account="onAccountChange"
    v-on="$listeners"
  >
    <template #account="{ attrs, on }">
      <AccountSelect
        v-model="form.integrationHomebaseId"
        name="integrationHomebaseId"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <VxFormGroup
      v-if="form.integrationHomebaseId > 0"
      :title="
        $t('settings.automation.homebase.connectAccount.schedule.groupTitle')
      "
    >
      <p>{{ locationsDescription }}</p>
      <v-row>
        <v-col cols="12" md="6">
          <VxSelect
            v-model="form.homebaseLocationId"
            :loading="syncingLocations"
            :no-data-text="
              $t(
                'settings.automation.homebase.connectAccount.schedule.select.noDataText'
              )
            "
            name="homebaseLocationId"
            :label="
              $t(
                'settings.automation.homebase.connectAccount.schedule.select.label'
              )
            "
            :placeholder="
              $t(
                'settings.automation.homebase.connectAccount.schedule.select.placeholder'
              )
            "
            rules="required"
            :items="selectedAccountsLocations"
          />
        </v-col>
      </v-row>
      <p class="text--secondary text-caption">
        {{
          $t("settings.automation.homebase.connectAccount.schedule.syncText")
        }}
        <RaiLink
          :disabled="syncingLocations"
          class="text-caption"
          @click="syncLocations"
        >
          {{
            $t(
              "settings.automation.homebase.connectAccount.schedule.syncBtnText"
            )
          }}
        </RaiLink>
      </p>
    </VxFormGroup>
  </AccountSetup>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import AccountSetup from "../AccountSetup/AccountSetup.vue";
import AccountSelect from "../AccountSelect/AccountSelect.vue";
import { VxFormGroup, VxSelect, RaiLink } from "@/core-ui";

export default {
  name: "ConnectAccountSetup",
  components: { AccountSetup, AccountSelect, VxFormGroup, VxSelect, RaiLink },
  mixins: [
    useFormObject({
      prop: "automation",
      default: {
        integrationHomebaseId: null,
        homebaseLocationId: null,
      },
    }),
  ],
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
    storeNumber: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    syncingLocations: false,
  }),
  computed: {
    locationsDescription() {
      return this.$t(
        "settings.automation.homebase.connectAccount.schedule.description",
        {
          storeNumber: this.storeNumber,
        }
      );
    },
    selectedAccountsLocations() {
      const selectedAccount = this.accounts.find(
        (acc) => acc.value === this.form.integrationHomebaseId
      );

      return selectedAccount?.locations || [];
    },
  },
  methods: {
    onAccountChange() {
      this.form.homebaseLocationId = null;
      this.$emit("select:account", { ...this.form });
    },
    syncLocations() {
      this.syncingLocations = true;
      this.$emit("sync:locations", {
        values: { ...this.form },
        resolve: () => {
          this.syncingLocations = false;
        },
      });
    },
    updateAutomation(formContext) {
      this.$emit("update:automation", formContext);
    },
  },
};
</script>
