import { lowerCase } from "lodash";

/**
 * Resolves value as Title case
 * @param {String} value - text to be converted
 * @returns {String} Returns value in Title case
 */
const titleCase = (value) => {
  return value[0].toUpperCase() + lowerCase(value.slice(1, value.length));
};

export { titleCase };
export default titleCase;
