<template>
  <RaiSetup
    :title="$t('settings.automation.nightlyReports.review.title')"
    type="info"
    hide-next
  >
    <VxFormGroup
      :title="
        $t('settings.automation.nightlyReports.review.sentEmailGroup.title')
      "
    >
      <ReviewReportList :reports="reports" />
    </VxFormGroup>
  </RaiSetup>
</template>

<script>
import ReviewReportList from "./ReviewReportList.vue";
import { RaiSetup, VxFormGroup } from "@/core-ui";

export default {
  name: "ReviewSetup",
  components: { RaiSetup, VxFormGroup, ReviewReportList },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
