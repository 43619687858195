<template>
  <AutomationCard v-bind="$attrs" :automation="automation" v-on="$listeners" />
</template>

<script>
import { AutomationType } from "../../constants";
import { useAutomation } from "../../mixins/useAutomation";

export default {
  name: "NightlyReportsCard",

  mixins: [useAutomation({ type: AutomationType.NIGHTLY_REPORTS })],
};
</script>
