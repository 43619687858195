var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccountSetup',_vm._g(_vm._b({attrs:{"accounts":_vm.accounts},on:{"submit":_vm.updateAutomation,"change:account":_vm.onAccountChange},scopedSlots:_vm._u([{key:"account",fn:function({ attrs, on }){return [_c('AccountSelect',_vm._g(_vm._b({attrs:{"name":"integrationHomebaseId"},model:{value:(_vm.form.integrationHomebaseId),callback:function ($$v) {_vm.$set(_vm.form, "integrationHomebaseId", $$v)},expression:"form.integrationHomebaseId"}},'AccountSelect',attrs,false),on))]}}])},'AccountSetup',_vm.$attrs,false),_vm.$listeners),[(_vm.form.integrationHomebaseId > 0)?_c('VxFormGroup',{attrs:{"title":_vm.$t('settings.automation.homebase.connectAccount.schedule.groupTitle')}},[_c('p',[_vm._v(_vm._s(_vm.locationsDescription))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('VxSelect',{attrs:{"loading":_vm.syncingLocations,"no-data-text":_vm.$t(
              'settings.automation.homebase.connectAccount.schedule.select.noDataText'
            ),"name":"homebaseLocationId","label":_vm.$t(
              'settings.automation.homebase.connectAccount.schedule.select.label'
            ),"placeholder":_vm.$t(
              'settings.automation.homebase.connectAccount.schedule.select.placeholder'
            ),"rules":"required","items":_vm.selectedAccountsLocations},model:{value:(_vm.form.homebaseLocationId),callback:function ($$v) {_vm.$set(_vm.form, "homebaseLocationId", $$v)},expression:"form.homebaseLocationId"}})],1)],1),_c('p',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(_vm.$t("settings.automation.homebase.connectAccount.schedule.syncText"))+" "),_c('RaiLink',{staticClass:"text-caption",attrs:{"disabled":_vm.syncingLocations},on:{"click":_vm.syncLocations}},[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.homebase.connectAccount.schedule.syncBtnText" ))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }