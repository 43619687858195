<template>
  <v-list class="py-0 overflow-y-auto" :max-height="maxHeight">
    <v-slide-y-transition group hide-on-leave>
      <template v-for="(email, index) in emails">
        <v-divider v-if="index !== 0" :key="index" />
        <v-list-item :key="email" dense>
          <v-list-item-content>
            <v-list-item-title>{{ email }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$emit('delete', { email, index })">
              <v-icon>$delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-slide-y-transition>
  </v-list>
</template>

<script>
export default {
  name: "RecipientStandaloneList",
  inheritAttrs: false,
  props: {
    emails: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: [String, Number],
      default: 500,
    },
  },
};
</script>
