var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SetupForm',_vm._g(_vm._b({attrs:{"title":_vm.$t('settings.automation.nightlyReports.recipient.title'),"next-text":_vm.$t('settings.automation.nightlyReports.recipient.nextText')},on:{"submit":_vm.updateAutomation}},'SetupForm',_vm.$attrs,false),_vm.$listeners),[_c('VxFormGroup',{attrs:{"title":_vm.$t('settings.automation.nightlyReports.recipient.teamMemberGroup.title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.nightlyReports.recipient.teamMemberGroup.description" ))+" ")]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('VxCheckbox',{staticClass:"mr-4",attrs:{"name":"sendToOwners","label":_vm.$t(
            'settings.automation.nightlyReports.recipient.teamMemberGroup.ownersLabel'
          ),"dense":""},model:{value:(_vm.form.sendToOwners),callback:function ($$v) {_vm.$set(_vm.form, "sendToOwners", $$v)},expression:"form.sendToOwners"}}),_c('VxCheckbox',{staticClass:"mr-4",attrs:{"name":"sendToDistrictManagers","label":_vm.$t(
            'settings.automation.nightlyReports.recipient.teamMemberGroup.districtManagersLabel'
          ),"dense":""},model:{value:(_vm.form.sendToDistrictManagers),callback:function ($$v) {_vm.$set(_vm.form, "sendToDistrictManagers", $$v)},expression:"form.sendToDistrictManagers"}}),_c('VxCheckbox',{staticClass:"mr-4",attrs:{"name":"sendToManagers","label":_vm.$t(
            'settings.automation.nightlyReports.recipient.teamMemberGroup.managersLabel'
          ),"dense":""},model:{value:(_vm.form.sendToManagers),callback:function ($$v) {_vm.$set(_vm.form, "sendToManagers", $$v)},expression:"form.sendToManagers"}})],1),_c('RecipientList',{attrs:{"team-members":_vm.teamMembers,"send-to-owners":_vm.form.sendToOwners,"send-to-district-managers":_vm.form.sendToDistrictManagers,"send-to-managers":_vm.form.sendToManagers}})],1),_c('VxFormGroup',{attrs:{"title":_vm.$t(
        'settings.automation.nightlyReports.recipient.standaloneEmailGroup.title'
      )}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.nightlyReports.recipient.standaloneEmailGroup.description" ))+" ")]),_c('VxTextField',{ref:"emailInput",attrs:{"autocomplete":"off","name":"recipientEmail","label":_vm.$t(
          'settings.automation.nightlyReports.recipient.standaloneEmailGroup.emailInput.label'
        ),"placeholder":_vm.$t(
          'settings.automation.nightlyReports.recipient.standaloneEmailGroup.emailInput.placeholder'
        ),"rules":"email","mode":"passive","outlined":"","append-icon":"$plus"},on:{"click:append":_vm.onEmailInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.onEmailInput.apply(null, arguments)},"blur":_vm.onEmailBlur},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}}),_c('RecipientStandaloneList',{attrs:{"emails":_vm.form.standaloneEmails},on:{"delete":_vm.onEmailDelete}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }