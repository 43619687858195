<template>
  <v-list class="py-0 overflow-y-auto" :max-height="maxHeight" three-line>
    <template v-for="(report, index) in reports">
      <v-divider v-if="index !== 0" :key="index" />
      <ReviewReportListItem :key="report.title" :report="report" />
    </template>
  </v-list>
</template>

<script>
import ReviewReportListItem from "./ReviewReportListItem.vue";

export default {
  name: "ReviewReportList",
  components: { ReviewReportListItem },
  inheritAttrs: false,
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: [String, Number],
      default: 500,
    },
  },
};
</script>
