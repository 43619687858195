<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <RecipientSetup
      :automation="automation"
      :type="completedSetups[0]"
      :team-members="teamMembers"
      @update:automation="onAutomationUpdate"
      @next="onNext"
    />
    <ReviewSetup :reports="reports" />
  </RaiSetups>
</template>

<script>
import { RaiSetups, SetupType } from "@/core-ui";
import { RecipientSetup, ReviewSetup } from "./components/NightlyReports";
import {
  NIGHTLY_REPORTS_AUTOMATION_QUERY,
  NIGHTLY_REPORTS_AUTOMATION_UPDATE,
  NIGHTLY_REPORTS_TEAM_MEMBERS_QUERY,
  NIGHTLY_REPORTS_LOGS_QUERY,
} from "./graphql/NightlyReports";

export default {
  name: "NightlyReportsView",
  components: { RaiSetups, RecipientSetup, ReviewSetup },
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    activeSetup: 0,
    loading: false,
    teamMembers: [],
    reports: [],
    completedSetups: new Array(2),
  }),
  apollo: {
    automation: {
      query: NIGHTLY_REPORTS_AUTOMATION_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return response.nightlyReportsAutomation;
      },
    },
    teamMembers: {
      query: NIGHTLY_REPORTS_TEAM_MEMBERS_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return response.users;
      },
    },
    reports: {
      query: NIGHTLY_REPORTS_LOGS_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return response.nightlyReportsLogs;
      },
    },
  },
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
    },
    async onAutomationUpdate(values, resolve) {
      const { data } = await this.$apollo.mutate({
        mutation: NIGHTLY_REPORTS_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      if (data.errors?.length > 0) {
        console.error("ERROR: Nightly Reports Update");
        return;
      }

      this.automation = values;
      resolve();
    },
  },
};
</script>
