<template>
  <v-row class="ma-0">
    <v-col cols="12" md="6" lg="4" xl="3">
      <WhenIWorkCard :automation-status="whenIWork" @connect="onConnect" />
    </v-col>

    <v-col cols="12" md="6" lg="4" xl="3">
      <HomebaseCard :automation-status="homebase" @connect="onConnect" />
    </v-col>

    <v-col cols="12" md="6" lg="4" xl="3">
      <ConstantContactCard
        :automation-status="constantContact"
        @connect="onConnect"
      />
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <FivestarsCard :automation-status="fivestars" @connect="onConnect" />
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <NightlyReportsCard
        :automation-status="nightlyReports"
        @connect="onConnect"
      />
    </v-col>
    <v-col cols="12" md="6" lg="4" xl="3">
      <QuickbooksCard :automation-status="quickbooks" @connect="onConnect" />
    </v-col>
  </v-row>
</template>

<script>
import {
  WhenIWorkCard,
  HomebaseCard,
  ConstantContactCard,
  FivestarsCard,
  NightlyReportsCard,
  QuickbooksCard,
} from "./components";
import { AUTOMATION_STATUS } from "./graphql";
import { AutomationType } from "./constants";

export default {
  name: "AutomationView",
  components: {
    WhenIWorkCard,
    HomebaseCard,
    ConstantContactCard,
    FivestarsCard,
    NightlyReportsCard,
    QuickbooksCard,
  },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    whenIWork: {},
    homebase: {},
    constantContact: {},
    fivestars: {},
    quickbooks: {},
    nightlyReports: {},
  }),
  apollo: {
    whenIWork: {
      query: AUTOMATION_STATUS,
      variables() {
        return { storeId: this.storeId, type: AutomationType.WHEN_I_WORK };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      update({ automationStatus }) {
        return automationStatus;
      },
    },
    homebase: {
      query: AUTOMATION_STATUS,
      variables() {
        return { storeId: this.storeId, type: AutomationType.HOMEBASE };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      update({ automationStatus }) {
        return automationStatus;
      },
    },
    constantContact: {
      query: AUTOMATION_STATUS,
      variables() {
        return { storeId: this.storeId, type: AutomationType.CONSTANT_CONTACT };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      update({ automationStatus }) {
        return automationStatus;
      },
    },
    fivestars: {
      query: AUTOMATION_STATUS,
      variables() {
        return { storeId: this.storeId, type: AutomationType.FIVESTARS };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      update({ automationStatus }) {
        return automationStatus;
      },
    },
    quickbooks: {
      query: AUTOMATION_STATUS,
      variables() {
        return { storeId: this.storeId, type: AutomationType.QUICKBOOKS };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      update({ automationStatus }) {
        return automationStatus;
      },
    },
    nightlyReports: {
      query: AUTOMATION_STATUS,
      variables() {
        return { storeId: this.storeId, type: AutomationType.NIGHTLY_REPORTS };
      },
      watchLoading(loading) {
        this.setLoading(loading);
      },
      update({ automationStatus }) {
        return automationStatus;
      },
    },
  },
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    onConnect(automation) {
      this.$router.push({
        name: `settings.automation.${automation.type}`,
        params: { storeId: this.storeId },
      });
    },
  },
};
</script>
