<template>
  <div class="automation-card__status" :class="classes">
    <template v-if="isActive">
      <VIcon left color="success"> $success </VIcon>
      <span> {{ $t("settings.automation.card.activeStatusText") }} </span>
    </template>
    <template v-else-if="isConnected">
      <VIcon left color="warning"> $warning </VIcon>
      <span>
        {{ $t("settings.automation.card.connectedStatusText") }}
      </span>
    </template>
    <template v-else-if="isError">
      <VIcon left color="error"> $error </VIcon>
      <span>
        {{ $t("settings.automation.card.errorStatusText") }}
      </span>
    </template>
  </div>
</template>

<script>
import { AutomationStatus } from "../../constants";

export default {
  name: "AutomationCardStatus",
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: AutomationStatus.NOT_CONNECTED,
    },
  },
  computed: {
    classes() {
      return {
        "success--text": this.isActive,
        "warning--text": this.isConnected,
        "error--text": this.isError,
        "text-overline text-ellipsis ma-2": true,
      };
    },
    isActive() {
      return this.status === AutomationStatus.ACTIVE;
    },
    isConnected() {
      return this.status === AutomationStatus.CONNECTED;
    },
    isError() {
      return this.status === AutomationStatus.ERROR;
    },
  },
};
</script>

<style lang="scss">
.automation-card__status {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
