<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.homebase.optionalSettings.title')"
    :next-text="$t('settings.automation.homebase.optionalSettings.nextText')"
    @submit="updateSettings"
    v-on="$listeners"
  >
    <RaiSetupToggle
      v-model="form.useTimeclock"
      name="useTimeclock"
      :title="
        $t('settings.automation.homebase.optionalSettings.useTimeclock.title')
      "
      :subtitle="
        $t(
          'settings.automation.homebase.optionalSettings.useTimeclock.subtitle'
        )
      "
    />
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import { RaiSetupToggle } from "@/core-ui";
import { useFormObject } from "@/mixins/useFormObject";

export default {
  name: "OptionalSettingsSetup",
  components: {
    SetupForm,
    RaiSetupToggle,
  },
  mixins: [
    useFormObject({
      prop: "settings",
      default: {
        useTimeclock: false,
      },
    }),
  ],
  methods: {
    updateSettings(formContext) {
      this.$emit("update:settings", formContext);
    },
  },
};
</script>
