<template>
  <TextWithLinksStep
    :title="$t('settings.automation.homebase.trainTeam.title')"
    :sections="$t('settings.automation.homebase.trainTeam.sections')"
  />
</template>

<script>
import TextWithLinksStep from "@/views/Common/TextWithLinksStep.vue";

export default {
  name: "TrainTeamSetup",
  components: {
    TextWithLinksStep,
  },
};
</script>
