<template>
  <v-skeleton-loader
    type="image"
    :height="height"
    :loading="loading"
    style="overflow: hidden"
  >
    <v-sheet :color="sheetColor" class="automation-card__logo">
      <v-img :src="logoSrc" v-bind="$attrs" :height="height" contain />
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: "AutomationCardLogo",
  inheritAttrs: false,
  props: {
    fileName: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: 150,
    },
  },
  computed: {
    sheetColor() {
      return this.$vuetify.theme.dark ? "grey" : "grey lighten-3";
    },
    logoSrc() {
      return this.fileName
        ? require(`@/assets/automation/${this.fileName}`)
        : "";
    },
  },
};
</script>

<style lang="scss">
.automation-card__logo {
  display: grid;
  align-content: center;
  justify-content: center;
}
</style>
