<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.nightlyReports.recipient.title')"
    :next-text="$t('settings.automation.nightlyReports.recipient.nextText')"
    @submit="updateAutomation"
    v-on="$listeners"
  >
    <VxFormGroup
      :title="
        $t('settings.automation.nightlyReports.recipient.teamMemberGroup.title')
      "
    >
      <p>
        {{
          $t(
            "settings.automation.nightlyReports.recipient.teamMemberGroup.description"
          )
        }}
      </p>
      <div class="d-flex align-center flex-wrap">
        <VxCheckbox
          v-model="form.sendToOwners"
          class="mr-4"
          name="sendToOwners"
          :label="
            $t(
              'settings.automation.nightlyReports.recipient.teamMemberGroup.ownersLabel'
            )
          "
          dense
        />
        <VxCheckbox
          v-model="form.sendToDistrictManagers"
          class="mr-4"
          name="sendToDistrictManagers"
          :label="
            $t(
              'settings.automation.nightlyReports.recipient.teamMemberGroup.districtManagersLabel'
            )
          "
          dense
        />
        <VxCheckbox
          v-model="form.sendToManagers"
          class="mr-4"
          name="sendToManagers"
          :label="
            $t(
              'settings.automation.nightlyReports.recipient.teamMemberGroup.managersLabel'
            )
          "
          dense
        />
      </div>
      <RecipientList
        :team-members="teamMembers"
        :send-to-owners="form.sendToOwners"
        :send-to-district-managers="form.sendToDistrictManagers"
        :send-to-managers="form.sendToManagers"
      />
    </VxFormGroup>
    <VxFormGroup
      :title="
        $t(
          'settings.automation.nightlyReports.recipient.standaloneEmailGroup.title'
        )
      "
    >
      <p>
        {{
          $t(
            "settings.automation.nightlyReports.recipient.standaloneEmailGroup.description"
          )
        }}
      </p>
      <VxTextField
        ref="emailInput"
        v-model="emailAddress"
        autocomplete="off"
        name="recipientEmail"
        :label="
          $t(
            'settings.automation.nightlyReports.recipient.standaloneEmailGroup.emailInput.label'
          )
        "
        :placeholder="
          $t(
            'settings.automation.nightlyReports.recipient.standaloneEmailGroup.emailInput.placeholder'
          )
        "
        rules="email"
        mode="passive"
        outlined
        append-icon="$plus"
        @click:append="onEmailInput"
        @keydown.enter.prevent="onEmailInput"
        @blur="onEmailBlur"
      />
      <RecipientStandaloneList
        :emails="form.standaloneEmails"
        @delete="onEmailDelete"
      />
    </VxFormGroup>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import RecipientList from "./RecipientList.vue";
import RecipientStandaloneList from "./RecipientStandaloneList.vue";
import { VxFormGroup, VxCheckbox, VxTextField } from "@/core-ui";
import { useFormObject } from "@/mixins/useFormObject";

// ! FIX: TRIGGERS FORM SUBMIT ON KEYDOWN ENTER OF EMAIL INPUT

export default {
  name: "RecipientSetup",
  components: {
    SetupForm,
    VxFormGroup,
    VxCheckbox,
    VxTextField,
    RecipientList,
    RecipientStandaloneList,
  },
  mixins: [
    useFormObject({
      prop: "automation",
      default: {
        sendToOwners: false,
        sendToDistrictManagers: false,
        sendToManagers: false,
        standaloneEmails: [],
      },
    }),
  ],
  props: {
    teamMembers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    emailAddress: "",
  }),
  computed: {
    isEmailEmpty() {
      return this.emailAddress.trim() === "";
    },
  },
  methods: {
    onEmailBlur() {
      if (this.isEmailEmpty) {
        this.$refs.emailInput.validator().reset();
      }
    },
    async onEmailInput() {
      const validator = this.$refs.emailInput.validator();
      const { valid } = await validator.validate();

      if (!valid || this.isEmailEmpty) {
        return;
      }

      const emails = this.form.standaloneEmails;
      const emailExists = emails.some((email) => this.emailAddress === email);

      if (emailExists) {
        this.emailAddress = "";
        return;
      }

      emails.push(this.emailAddress);
      this.emailAddress = "";
    },
    async onEmailDelete({ index }) {
      const confirm = await this.$dialog.confirm({
        title: "Delete email",
        message: "Are you sure you want to delete this email?",
      });

      if (!confirm) {
        return;
      }

      this.form.standaloneEmails.splice(index, 1);
    },
    updateAutomation({ resolve }) {
      this.$emit("update:automation", { ...this.form }, resolve);
    },
  },
};
</script>
